import { render, staticRenderFns } from "./PrincipalMessage.vue?vue&type=template&id=605b7cfb&scoped=true"
import script from "./PrincipalMessage.vue?vue&type=script&lang=js"
export * from "./PrincipalMessage.vue?vue&type=script&lang=js"
import style0 from "./PrincipalMessage.vue?vue&type=style&index=0&id=605b7cfb&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "605b7cfb",
  null
  
)

export default component.exports