import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: '/',
            name: 'home',
            component: () => import('../components/Home.vue'),
            meta: {
                pageTitle: 'Home',
                breadcrumb: [
                    {
                        text: 'Home',
                        active: true,
                    },
                ],
            },
        },
        {
            path:'/contact/us',
            name: 'contact-us',
            component: () => import('../components/pages/ContactUs'),
            meta: {
                pageTitle: 'Contact Us',
                breadcrumb: [
                    {
                        text: 'Contact Us',
                        active: true,
                    },
                ],
            },
        },
        {
            path:'/common/:menu/:slug',
            name: 'common.page',
            props:true,
            component: () => import('../components/Common'),
            meta: {
                pageTitle: 'Common',
                breadcrumb: [
                    {
                        text: 'Common',
                        active: true,
                    },
                ],
            },
        },
        {
            path:'/single:id/event',
            name: 'event.single',
            props:true,
            component: () => import('../components/pages/notice/EventsSingle'),
            meta: {
                pageTitle: 'Single event',
                breadcrumb: [
                    {
                        text: 'Single event',
                        active: true,
                    },
                ],
            },
        },
        {
            path:'/all/facility',
            name: 'faculty',
            component: () => import('../components/pages/faculty/AllFaculties'),
            meta: {
                pageTitle: 'Facility list',
                breadcrumb: [
                    {
                        text: 'Facility list',
                        active: true,
                    },
                ],
            },
        },
        {
            path:'/faculty/details:id',
            name: 'faculty-details',
            props:true,
            component: () => import('../components/pages/faculty/FacultyDetails'),
            meta: {
                pageTitle: 'Facility Details',
                breadcrumb: [
                    {
                        text: 'Facility Details',
                        active: true,
                    },
                ],
            },
        },
        {
            path:'/online/admission',
            name: 'apply-online',
            component: () => import('../components/pages/admission/ApplyOnlineFirst'),
            meta: {
                pageTitle: 'Admission',
                breadcrumb: [
                    {
                        text: 'Admission',
                        active: true,
                    },
                ],
            },
        },
        {
            path:'/apply/online/admission',
            name: 'applyonlinesecond',
            component: () => import('../components/pages/admission/ApplyOnlineSecond'),
            meta: {
                pageTitle: 'Apply Online',
                breadcrumb: [
                    {
                        text: 'Apply Online',
                        active: true,
                    },
                ],
            },
        },
        {
            path:'/president/message',
            name: 'presidentMessage',
            component: () => import('../components/pages/PresidentMessage'),
            meta: {
                pageTitle: 'President Message',
                breadcrumb: [
                    {
                        text: 'President Message',
                        active: true,
                    },
                ],
            },
        },
        {
            path:'/principal/message',
            name: 'principalMessage',
            component: () => import('../components/pages/PrincipalMessage'),
            meta: {
                pageTitle: 'Principal Message',
                breadcrumb: [
                    {
                        text: 'Principal Message',
                        active: true,
                    },
                ],
            },
        },
        {
            path:'/slider/news:id',
            name: 'slider_details',
            props:true,
            component: () => import('../components/pages/home/SliderDetails'),
            meta: {
                pageTitle: 'Slider Info',
                breadcrumb: [
                    {
                        text: 'Slider Info',
                        active: true,
                    },
                ],
            },
        },
    ],

})
router.beforeEach((to, _, next) => {
    return next()
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    /*const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }*/
    window.scrollTo(0,0);
})
export default router