
<template> 
  <div class="header-container">
    <div class="header-row">
      <div class="header-column">
       <router-link :to="{name:'home'}"><strong><img src="/images/header/logo.jpg" alt="Logo" width="120"></strong></router-link>
      </div>
      <div class="header-column title">
        <h1 style="margin-bottom: 0px;" class="header_text">SREEPUR SECONDARY GIRLS SCHOOL</h1>
        <p style="color: black;">School ID : 01015396 | School Code : 7088 | EIIN : 118144</p>
      </div>
      <div class="header-column action">
        <a href="https://mycampus.sreepurgirls.edu.bd" target="_blank" class="pill-button">Login</a>
      </div>
    </div>
  </div>
  
  </template>
  
  <script>
    export default {
  
         methods: {
      handleScroll () {
  
      },
      backendUrl(){
        //window.location.href=process.env.VUE_APP_ENV_BACKENDURL;
        return process.env.VUE_APP_ENV_BACKENDURL;
      }
    },
    };
  </script>
  
  <style>
  
  .header_text {
    font-size: 40px;
    font-weight: 500;
    color: purple;
  }
  
  
  /* General header container styling */
  .header-container {
    width: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
    text-align: left;
  }
  
  /* Flexbox row layout */
  .header-row {
    display: flex;
    justify-content: space-between; /* Spreads the columns evenly */
    align-items: center; /* Vertically centers content */
    margin: 0 auto; /* Centers the content horizontally */
    padding: 0 20px; /* Adds padding to the sides */
  }
  
  /* Column for the logo */
  .header-column.logo {
    width: 150px;
  }
  
  /* Column for the title or central content */
  .header-column.title {
    flex-grow: 1; /* Makes this column take up the remaining space */
    margin-bottom: 0;
    font-weight: 550;
    font-family: 'Archivo Black', sans-serif;
    text-align: left;
  }
  
  /* Column for the call-to-action or login button */
  .header-column.action {
    text-align: right;
  }
  
  .header-btn {
    display: inline-block;
    padding: 10px 20px;
    background-color:rgb(255, 160, 122); /* Bootstrap blue color */
    color: rgb(10, 10, 10);
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
  }
  
  .header-btn:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }

  .pill-button {
  display: inline-block; /* Allows the element to size based on content */
  padding: 10px 30px; /* Controls the size of the button */
  font-size: 16px; /* Adjusts the text size */
  font-weight: bold; /* Makes the text bold */
  color: white; /* Text color */
  background-color: #800000; /* Button background color */
  border: none; /* Removes any default borders */
  border-radius: 50px; /* Ensures a rounded, pill-like shape */
  text-decoration: none; /* Removes underline for links */
  text-align: center; /* Centers the text */
  cursor: pointer; /* Changes cursor to pointer on hover */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Smooth hover effect */
}

.pill-button:hover {
  background-color: #45a049; /* Darkens the button color on hover */
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2); /* Increases shadow on hover */
}

.pill-button:active {
  background-color: #3e8e41; /* Even darker shade when clicked */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Subtle inset shadow */
}

  
  </style>