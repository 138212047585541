<template>
  <div class="mt-4">
    <!-- Footer Start -->
    <footer class="footer" style="background-color: white;">
      <div class="row">
        <div class="col-sm-3 footer-text">
          <div class="ml-4">
            <span style="font-size: 17px;"><strong>Sreepur Secondary Girls School.</strong></span><br>
            <span style="font-size: 17px;"><strong>Sreepur, Magura.</strong></span><br>
            <span style="font-size: 17px;"><strong>Khulna Division, Bangladesh.</strong></span><br>
            <span style="font-size: 17px;"><strong>Mobile:</strong></span> <span style="font-size: 14px">+8801712708936 & +8801316081119</span><br>
            <span style="font-size: 17px;"><strong>Email:</strong></span> <span style="font-size: 14px">sreepurgirls.edu.bd@gmail.com</span><br>
            <ul class="list-unstyled mt-2">
              <li class="list-inline-item mr-1" v-for="(item,key) in social_links" :key="key">
                <a :href="item.link" target="_blank" class="rounded">
                  <img :src="imgShow(item.image)" :alt="item.name" style="border-radius: 5px">
                </a>
              </li>
            </ul>
          </div>

        </div><!--end col-->
        <div  class="col-sm-1"></div>
        <div class="col-sm-4" >
          <div class=" border-0" >
            <div class="card-body p-0">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d936026.2420402085!2d88.24374804687498!3d23.591687500000013!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39fef7e1b876f693%3A0x3d59a1b4b318a2f3!2sSreepur%20Secondary%20Girls%20School!5e0!3m2!1sen!2sbd!4v1733481042345!5m2!1sen!2sbd" width="500" height="200" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div><!--end col-->
        <div  class="col-sm-1"></div>
        <div class="col-sm-3 footer-text">
          <div class="ml-1">
            <span style="font-size: 17px;"><strong>Important Links</strong></span><br>
            <ul class="footer-list mt-1">
              <li v-for="(item,key) in footerByType(3)" :key="key">
                <template v-if="item.is_link">
                  <a :href="item.link" class="footer-text" target="_blank">{{ item.title }}</a>
                </template>
                <template v-else>
                  <router-link :to="item.is_link?item.link:{name:'footer.common',params:{slug:item.slug}}" exact class="footer-text">
                    <i class="uil uil-angle-right-b me-1"></i>{{ item.title }}
                  </router-link>
                </template>

              </li>
            </ul>
          </div>
        </div><!--end col-->
    </div><!--end row-->
    </footer><!--end footer-->
    <footer class=" mt-4 footer-text">
      <div class="text-sm-center" style="background-color: #00598b;">
              <p class="mb-0" style="font-size: 17px; color: white;">©Copyright {{new Date().getFullYear()}}. All Rights Reserved.
                <strong>Sreepur Secondary Girls School</strong><br>
              </p>
              <p class="mb-0" style="font-size: 17px;"> <span style=" color: white">Design & Developed by</span> <a href="https://optisoftbd.com/" target="_blank" class="text-reset"><span style="color: coral"><b>OptiSoft IT Solutions</b></span></a></p>
            </div>
    </footer><!--end footer-->
    <!-- Footer End -->
  </div>
</template>

<script>
import apiCall from "../../core/apiCall";
export default {
  name:"AppFooter",
  data(){
    return {
      error: '',
      success: '',
    }
  },
  created(){
    if(this.footers.length <1) this.$store.dispatch('GET_ALL_FOOTER');
    if(this.social_links.length <1) this.$store.dispatch('GET_ALL_SOCIAL_LINK');
    if(this.logo_name.length <1) this.$store.dispatch('GET_LOGO_AND_NAME');
    if(this.contactinformation.length <1) this.$store.dispatch('GET_ALL_CONTACTINFORMATION');
  },
  methods:{
    imgShow(dt){
      return process.env.VUE_APP_ENV_RESOURCHURL+dt;
    },
    subscribe(){
      apiCall.post('/subscriber/store',new FormData(this.$refs.subscriber_form)).then((response)=>{
        if(response.data.status==='success') {
          this.success = response.data.message;
          this.error='';
        }
        else {
          this.success='';
          this.error = response.data.message;
        }
        this.$refs.subscriber_form.reset();
      }).catch(()=>{
        this.error='Invalid request';
        this.success='';
        this.$refs.subscriber_form.reset();
      })
    },
    footerByType(type){
      return this.footers.filter(item=>parseInt(item.type)===type);
    },
  },
  computed:{
    footers(){
      return this.$store.getters.footers;
    },
    social_links(){
      return this.$store.getters.social_links;
    },
    logo_name(){
      return this.$store.getters.logo_name;
    },
    contactinformation() {
      return this.$store.getters.contactinformation;
    },
  }
}
</script>

<style>
.footer-text{
  color: #000;
  size: 14px;
}
/* .footer .footer-head {
  color: #000 !important;
} */
a {
  color: #000;
  text-decoration: none;
  background-color: transparent;
}
</style>
